import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  NavigationToggleWrapper,
  NavigationWrapper,
  NavigationBarWrapper,
  NavigationOptions,
  NavigationLink,
  ModalLink,
  Footer,
} from "./style";
import ContactPage from "./Contact";
import Modal from "../Modal";
import ChangeLanguage from "../ChangeLanguage";
import { PageChange } from "../Scroll";
import { LocationProp, PageThemeType } from "../../../utils/types";
import MenuIcon from "-!svg-react-loader!../../../assets/images/menuIcon.svg";
import CloseIcon from "-!svg-react-loader!../../../assets/images/closeIcon.svg";

export interface NavigationRefMethods {
  setPageTheme: (theme?: PageThemeType) => void;
}

interface NavigationProps extends LocationProp {
  isModalOpen?: boolean;
  setModalState: (value: boolean) => void;
  setPageChange?: (value?: PageChange) => void;
  navigationThemes: PageThemeType | PageThemeType[];
  theme?: "dark" | "light";
  partial?: number;
  setPage?: (value: number) => void;
}

const Navigation: React.ForwardRefRenderFunction<
  NavigationRefMethods,
  NavigationProps
> = (
  {
    location,
    isModalOpen,
    setModalState,
    setPageChange,
    navigationThemes,
    theme,
    partial,
    setPage,
  },
  ref
) => {
  const { t } = useTranslation();
  const paths = [
    { title: "OKE", path: "/" },
    { title: "R&D", path: "/rnd" },
    { title: t(`career`), path: "/career" },
  ];
  const locationObject = location || window.location;
  let currentPath = locationObject.pathname;
  if (currentPath !== "/" && currentPath.slice(-1) === "/") {
    currentPath = currentPath.slice(0, -1);
  }
  const isStartingPage =
    currentPath.includes("offer") ||
    currentPath === "/rodo" ||
    currentPath === "/internalProcedure" ||
    currentPath === "/ourProjects" ||
    currentPath === "/rnd" ||
    currentPath === "/career"
      ? false
      : locationObject.hash === "";

  const homeObj = [
    "who-we-are",
    "technologies",
    "history",
    "our-clients",
    "csr",
    "acknowledgements",
    "eko",
    "oke",
  ];

  const rndObj = ["team", "civileo", "tv-stream-ai"];

  const careerObj = [
    "about-us",
    "recruitment",
    "internships",
    "current-job-offers",
    "what-we-offer",
    "join-us",
  ];

  let page =
    locationObject.pathname === "/"
      ? homeObj.indexOf(locationObject.hash.split("#")[1]) + 1
      : locationObject.pathname === "/rnd/"
      ? rndObj.indexOf(locationObject.hash.split("#")[1]) + 1
      : careerObj.indexOf(locationObject.hash.split("#")[1]) + 1;

  const [openNavigation, setOpenNavigation] = useState<boolean>(false);
  const [openContact, setOpenContact] = useState<boolean>(false);
  const [transitionOpen, setTransitionOpen] = useState<boolean>(false);
  const [transitionOpenContact, setTransitionOpenContact] = useState<boolean>(
    false
  );
  const [themeOverride, setThemeOverride] = useState<PageThemeType>();
  const [navigationTheme, setNavigationTheme] = useState<PageThemeType>(
    Array.isArray(navigationThemes) ? navigationThemes[page] : navigationThemes
  );

  useImperativeHandle(ref, () => ({
    setPageTheme: (theme?: PageThemeType) => {
      theme === "white"
        ? setThemeOverride("black")
        : theme === "black" || theme === "blue"
        ? setThemeOverride("white")
        : setThemeOverride(undefined);
    },
  }));

  useEffect(() => {
    document.body.addEventListener("scroll", handleNavThemeChange, {
      passive: true,
    });

    return () => {
      document.body.removeEventListener("scroll", handleNavThemeChange);
    };
  }, [page]);

  useEffect(() => {
    if (Array.isArray(navigationThemes[page - 1])) {
      handleNavThemeChange();
    } else {
      setNavigationTheme(
        Array.isArray(navigationThemes)
          ? navigationThemes[page - 1]
          : navigationThemes
      );
    }
  }, [page]);

  useEffect(() => {
    handleCloseNavigation();
  }, [isStartingPage]);

  const handleNavThemeChange = () => {
    const currentPage = navigationThemes[page - 1];

    if (Array.isArray(currentPage)) {
      const scroll = Math.ceil(document.body.scrollTop);
      const windowHeight = document.body.clientHeight;
      const toggleTopMargin = windowHeight / 10;
      let themeIndex = partial
        ? Math.floor((scroll + toggleTopMargin) / (windowHeight * partial))
        : Math.floor((scroll + toggleTopMargin) / windowHeight);
      if (themeIndex >= currentPage.length) {
        themeIndex = currentPage.length - 1;
      }
      const newTheme = currentPage[themeIndex] as PageThemeType;

      setNavigationTheme(newTheme);
    }
  };

  const handleOpenNavigation = () => {
    setTransitionOpen(true);
    setModalState(true);
    setOpenNavigation(true);
  };

  const handleCloseNavigation = () => {
    setTransitionOpen(false);
    setTimeout(() => {
      setModalState(false);
      setOpenNavigation(false);
    }, 600);
  };

  const handleOpenContactModal = () => {
    setTransitionOpenContact(true);
    isStartingPage && currentPath !== "/" && setModalState(true);
    setOpenContact(true);
  };

  const handleCloseContactModal = () => {
    setTransitionOpenContact(false);
    setTimeout(() => {
      isStartingPage && currentPath !== "/" && setModalState(false);
      setOpenContact(false);
    }, 600);
  };

  const goToPage = (path: string) => {
    if (path === currentPath) {
      if (locationObject.hash === "") {
        return;
      } else {
        setPageChange && setPageChange();
        setPage && setPage(0);
        navigate(".");
        handleCloseNavigation();
      }
    }
    setTimeout(() => {
      navigate(path);
    }, 600);
  };

  return (isStartingPage && currentPath !== "/") ||
    openNavigation ||
    transitionOpen ||
    transitionOpenContact ? (
    <>
      <Modal
        open={openContact}
        handleClose={handleCloseContactModal}
        style={{ background: "none", overflowY: "auto", width: "100%" }}
      >
        <ContactPage
          transitionOpen={transitionOpenContact}
          handleClose={handleCloseContactModal}
        />
      </Modal>

      <NavigationToggleWrapper>
        <CloseIcon onClick={handleCloseNavigation} />
      </NavigationToggleWrapper>

      <NavigationWrapper
        startingPage={isStartingPage ? 1 : 0}
        showBackground={currentPath === "/" ? 1 : 0}
        theme={theme}
        open={transitionOpen ? 1 : 0}
      >
        <NavigationBarWrapper
          startingPage={isStartingPage ? 1 : 0}
          theme={isStartingPage && currentPath === "/" ? "transparent" : theme}
        >
          <NavigationOptions>
            {paths &&
              paths.map(({ title, path }, index) => (
                <NavigationLink
                  onClick={() => goToPage(path)}
                  active={
                    path === currentPath && locationObject.hash === "" ? 1 : 0
                  }
                  mainPage={path === currentPath ? 1 : 0}
                  key={index}
                >
                  {title}
                </NavigationLink>
              ))}
          </NavigationOptions>
        </NavigationBarWrapper>
        <Footer>
          <ModalLink onClick={handleOpenContactModal}>{t(`contact`)}</ModalLink>
          <ChangeLanguage />
        </Footer>
      </NavigationWrapper>
    </>
  ) : !isModalOpen ? (
    <NavigationToggleWrapper
      show={1}
      navigationTheme={
        isStartingPage && currentPath === "/"
          ? "black"
          : themeOverride
          ? themeOverride
          : navigationTheme
      }
    >
      <MenuIcon onClick={handleOpenNavigation} />
    </NavigationToggleWrapper>
  ) : null;
};

export default forwardRef(Navigation);
